<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="create"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.terms.add') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.terms.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="false"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="editItem"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value+'' | highlight(search)" />
                    </template>

                    <template #item.title="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <!--                    <template #item.body="{ value }">
                        <div :inner-html.prop="value" />
                    </template>-->
                    <template #item.order="{ value }">
                        <div :inner-html.prop="value+'' | highlight(search)" />
                    </template>

                    <template #item.term_category_id="{ value }">
                        <div :inner-html.prop="termsCategories.find(e => e.id == value).name | highlight(search)" />
                    </template>

                    <template #item.company.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template v-slot:item.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>
                    <template v-slot:item.updated_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value) | highlight(search)" />
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"

                                    v-bind="attrs"
                                    @click.stop="editItem(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    @click.stop="deleteItem(item)"
                                    v-on="on"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>

                            <span>{{ $t('common.delete') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchTerms"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <confirm-dialog
                ref="confirm"
            />
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'
import ConfirmDialog from '@/components/common/ConfirmDialog'
import { debounce } from "debounce";

// import * as dateHelper from '@/helpers/date'
import wordsListKey from '@/components/admin/words/wordsListKey'
export default {
    components: { ConfirmDialog },
    data: function () {
        return {
            urlMain: 'legal/terms',
            dialog: false,
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('admin.posts.titleAtt'),
                    value: 'title'
                },
                /* {
                    text: this.$t('admin.posts.body'),
                    value: 'body'
                }, */
                {
                    text: this.$t('admin.posts.order'),
                    value: 'order'
                },
                {
                    text: this.$t('admin.terms.term_category_id'),
                    value: 'term_category_id'
                },
                {
                    text: this.$t('admin.terms.company'),
                    value: 'company.name'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'created_at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'updated_at'
                },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            search: '',
            items: [],
            itemSelected: null,
            sortby: ['id'],
            /* pagination: {
                totalItems: 10
            }, */
            options: {
                itemsPerPage: 10
            },
            termsCategories: []
        }
    },
    watch: {
        /* options: {
            handler(newValue, oldValue) {
                this.fetchTerms(newValue)

                if (newValue.sortBy && newValue.sortBy.length > 1) {
                    this.$store.dispatch('snackbar/success', this.$t('common.multisort'))
                }
            }
        },
        search: {
            handler(newValue) {
                this.fetchTerms();
            }
        }, */

        dialog(val) {
            val || this.close()
        }
    },
    created() {
        this.fetchTerms();
    },

    mounted() {
        this.fetchTermsCategories();
    },

    methods: {
        fetchTerms: debounce(function (e) {
            this.fetchTermsFinal(e);
        }, 500),

        async fetchTermsFinal(data = this.options) {
            try {
                const words = await axios
                    .get(this.urlMain)

                this.initialize(words.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        async fetchTermsCategories() {
            try {
                const termsCategories = await axios
                    .get('legal/termCategories')

                this.termsCategories = termsCategories.data;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.terms ?? [];

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0
        },
        create() {
            this.$router.push({ name: 'TermsCreate', params: { termsCategories: JSON.stringify(this.termsCategories) } });
        },

        editItem(item) {
            this.$router.push({ name: 'TermsCreate', params: { item, termsCategories: JSON.stringify(this.termsCategories) } })
        },
        async deleteItemConfirmed(item) {
            const urlAppend = item.id ? '/' + item.id : '';

            try {
                const result = await axios
                    .delete(this.urlMain + urlAppend);

                if (result.status === 200) {
                    this.fetchTerms();
                    // snackbar/
                    this.$store.dispatch('snackbar/success', this.$t('common.deleteOK'))
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                    console.log(error.response.headers)
                }
            }
            this.$store.dispatch('loading/end')
        },
        async deleteItem(item) {
            if (
                await this.$refs.confirm.open(
                    this.$t('common.confirmTitle'),
                    this.$t('common.confirmMSGDelete')
                )
            ) {
                this.deleteItemConfirmed(item)
            }
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.itemSelected = null
            })
        },
        filterTableItems(value, search, item) {
            let filter = false;
            if (value != null &&
                search != null) {
                if (typeof value === 'string') {
                    if (value === item.created_at || value === item.updated_at) {
                        filter = this.dateHelper.getDateTimeTableFormat(value).includes(search);
                    } else { filter = value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1 }
                } else if (typeof value === 'number') {
                    if (value === item.term_category_id) {
                        const found = this.termsCategories.find(e => e.id === value);
                        if (found) { filter = found.name.toUpperCase().includes(search.toUpperCase()); }
                    } else {
                        filter = (value + '').indexOf(search) !== -1;
                    }
                }
            }

            return filter;
        }
    }
}
</script>
